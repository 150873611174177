<template>
  <div v-if="visible">
    <div class="partner-intro" v-if="isOpen">
      <div class="overlay" />
      <transition name="fade" appear>
        <div class="partner-intro-content outro-content">
          <div class="logo">
            <PartnerLogo svg-path="/assets/any-circle.svg" />
          </div>
          <div class="partner-intro-text">
            <div class="flex flex-direction-column">
              <h3 class="h3">
                Opouštíte stránky půjčovny Ski a Bike Centrum Radotín
              </h3>
              <div class="flex justify-content-left">
                <tbody>
                  <b>Chystáte se vstoupit</b> na stránky online půjčovny sportovního vybavení <b>Anyrent.cz.</b>
                </tbody>
              </div>
              <div class="flex justify-content-left">
                <ul>
                  <li><b>Ujistěte se, že veškeré Vaše objednávky byly řádně dokončeny a nebo stornovány.</b></li>
                  <li><b>Online půjčovna Anyrent.cz</b> zajišťuje výpůjčky <b>sportovního vybavení pro léto i zimu po celém území ČR.</b></li>
                  <li>Pokud chcete vědět více, přejděte na stránky <a target="_blank" href="https://anyrent.cz/">Anyrent.cz</a> a nebo klikněte na odkaz <b><a target="_blank" href="https://www.anyrent.cz/jak-to-funguje">Jak to funguje?</a></b></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="partner-outro-buttons">
            <button-full
              class="back-button back-partner-button"
              wrap-style="button-full-wrap-gray"
              @click.native="outroNavigateBack()"
            >
              Zpět do půjčovny SBCR
            </button-full>
            <button-full
              class="continue-button continue-anyrent-button"
              wrap-style="button-full-wrap-partner"
              @click.native="outroNavigateNext()"
            >
              Přejít do  půjčovny Anyrent.cz
            </button-full>
          </div>
        </div>
      </transition>
    </div>
  </div>
  <div v-else>
    <div class="partner-intro" v-if="isOpen">
      <div class="overlay" />
      <transition name="fade" appear>
        <div class="partner-intro-content">
          <div class="logo">
            <PartnerLogo svg-path="/assets/SBCR_logo_header_plain.svg" />
          </div>
          <div class="partner-intro-text">
            <div class="flex flex-direction-column">
              <h2 class="h2">
                Vítejte na nových stránkách půjčovny SBCR
              </h2>
              <div class="flex justify-content-left">
                <tbody>
                  Nyní můžete rezervovat veškeré sportovní vybavení půjčovny Ski a Bike Centrum Radotín na těchto stránkách, které jsme připravili ve spolupráci s Anyrent.cz.
                </tbody>
              </div>
              <div class="flex justify-content-left">
                <ul>
                  <li>Výdej objednaného vybavení probíhá nadále na adrese <span class="partner-modal-text-bold">Ski Bike Centrum Radotín, Na Betonce 114/2, Praha 16 - Radotín</span></li>
                  <li> Dodatečné informace naleznete na stránce <b><a target="_blank" @click="navigateNext()" href="/static/pujcovna-sbcr">ZDE.</a></b></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="partner-intro-buttons">
            <button-full
              class="back-button"
              wrap-style="button-full-wrap-gray"
              @click.native="navigateBack()"
            >
              Zpět na stránky SBCR
            </button-full>
            <button-full
              class="continue-button"
              wrap-style="button-full-wrap-partner"
              @click.native="navigateNext()"
            >
              Zobrazit nabídku půjčovny SBCR
            </button-full>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>

import ButtonFull from 'theme/components/theme/ButtonFull'
import PartnerLogo from './../../../assets/PartnerLogo.vue'

export default {
  name: 'PartnerIntroduction',
  components: {
    ButtonFull,
    PartnerLogo
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    visible (newVal) {
      this.isOpen = newVal;
      this.toggleBodyScroll(newVal);
    },
    isOpen(newVal) {
      this.toggleBodyScroll(newVal);
    }
  },
  data () {
    return {
      isOpen: this.visible
    }
  },
  computed: {
  },
  mounted () {
    this.$store.dispatch('claims/check', {claimCode: 'partner-intro-seen'}).then((introSeen) => {
      if (!introSeen) {
        this.isOpen = true
        this.$store.dispatch('claims/set', {claimCode: 'partner-intro-seen', value: false})
      } else {
        this.isOpen = !introSeen.value
      }
    })
  },
  methods: {
    setVisited () {
      this.$store.dispatch('claims/set', {claimCode: 'partner-intro-seen', value: true})
    },
    accept () {
      this.setVisited()
      this.isOpen = false
    },
    navigateBack () {
      const url = `https://www.lyze-radotin.cz/`;
      window.location.href = url;
    },
    navigateNext () {
      this.accept();
      const url = localizedRoute({ name: 'home' });
      window.location.href = url;
    },
    outroNavigateBack () {
      this.visible = false;
      this.$emit('closeModal');
    },
    outroNavigateNext () {
      const url = `https://www.anyrent.cz`;
      window.location.href = url;
    },
    toggleBodyScroll(lock) {
      if (lock) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
@import '~theme/css/components/_checkboxes';
@import '~theme/css/layout/_margin';
@import '~theme/css/variables/assets';
@import '~theme/css/helpers/functions/asset';

$plain-logo-src: asset(partner-logo-plain);
$logo-src: asset(square-logo);
$partner-logo-src: asset(partner-logo-circle);

$color-white: color(white);
$color-black: color(black);
$color-gray: color(gray);
$color-anyrent: color(anyrent);
$color-partner: color(partner);

.partner-intro {
  z-index: 4;
  position: absolute;
  display: flex;
  width: 100vw;
  height: 100vh;
  inset: 0;
  justify-content: center;
  align-items: center;
  // background-color: $color-white;
}

.partner-outro-buttons, .partner-intro-buttons {
  display: flex;
  justify-content: space-evenly;
  padding-top: 25px;
  flex-wrap: wrap;
  gap: 5px;

  & button {
    width: unset;
    padding: 12px;
    border-radius: 38px;
    & ::v-deep *.wrap {
      display: flex;
      justify-content: center;
      min-width: 240px;
      padding: unset;
      background-color: unset;
    }
  }
}

.continue-button {
  background-color: $color-partner;
}

.continue-button.continue-anyrent-button {
  background-color: $color-anyrent;
}

.back-button {
  background-color: $color-gray;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-black;
  opacity: 0.7;
}

.partner-intro-content {
  max-width: 800px;
  background-color: $color-white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 80vw;
  padding: 25px;
}

.partner-intro-text {
  font-size: 18px;
  padding: 0 20px;
}

.partner-intro-content ul {
  margin-top: 0px;
}

.partner-intro-content ul li b {
  font-weight: 700;
}

.logo {
  height: 100px;
  justify-content: center;
}

.flex {
  display: flex;
}

.justify-content-left {
  justify-content: left;
}

.justify-content-center {
  justify-content: center;
}

.mt-auto {
  margin-top: auto;
}

.pb20 {
  padding-bottom: 20px;
}

.sidepadding {
  padding-top: 32px;
  padding-left: 20px;
  padding-right: 60px;
}

li {
  padding: 12px 0px 0px;
}

.flex-direction-column {
  flex-direction: column;
  gap: 12px;
}

h3 {
  margin-bottom: 0.75rem;
}

.partner-modal-text-bold {
  font-weight: 900;
}

.partner-intro-text a {
  text-decoration: underline;
}

@media (max-height: 720px) {
  li {
    padding: 12px 0px 0px;
  }

  .partner-intro-content .partner-intro-text {
    max-height: 50vh;
    overflow: scroll;
  }
}

@media (max-width: 575px) {
  .partner-intro-content {
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    padding: 0 20px 5vh;

    h2 {
      margin: 0.5rem 0;
    }

    .logo {
      padding: 0px;
      height: 65px;
      margin-bottom: 5px;
    }

    .flex {
      display: block;
    }

    .partner-intro-text {
      margin-top: 0px;
      font-size: 18px;
      padding: 0 20px;
    }

    .partner-intro-buttons, .partner-outro-buttons {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 10px 20px;
      flex-wrap: nowrap;

      button {
        font-size: 18px;
      }
    }
  }

  .overlay {
    display: none;
  }
}

</style>
